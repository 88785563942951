.tab button {
  border: none;
  cursor: pointer;
  text-align: center;
}

.icon-dropdown {
  background: url(../fontawesome/image/icon-dropdown.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.icon-droptop {
  background: url(../fontawesome/image/icon-droptop.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.icon-size {
  width: 24px;
  height: 24px;
  z-index: 50;
  margin-top: 12px;
  margin-left: 12px;
}
.tab button:disabled {
  height: 40px;
  border-radius: 16px;
  background-color: #003da5;
  width: 90%;
  color: white;
}
.tab {
  box-shadow: 2px -5px 20px 0px rgba(74, 74, 75, 0.5);
  border-radius: 13px;
  padding-bottom: 8px;
}

.tab2 button:disabled {
  height: 40px;
  border-radius: 11px;
  border: 1px solid #98ebff;
  background-color: #003da5;
  width: 90%;
  color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.tab2 button {
  cursor: pointer;
  text-align: center;
}
.tab2 {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
  border-radius: 16px;
  padding-bottom: 8px;
}
.border-table-listgift {
  background-color: #bdbdbd;
  border-radius: 5px;
  border: 0px solid #ffffff;
  padding: 5px;
  width: 75px;
  text-align: right;
  height: 30px;
}
.border-table-listprize {
  border-radius: 5px;
  padding-top: 2px;
  text-align: right;
  font-size: 11px;
  height: 30px;
}
.product-infomation {
  animation: move 0.6s ease-in-out;
}
.border-table-listwhitegift {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  width: 20px;
  text-align: right;
  height: 30px;
}
.text-item-gift {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #828282;
  line-height: 20px;
  max-width: 100%;
}

.item-status {
  font-size: 13px;
  line-height: 20px;
}
.img-code {
  border-radius: 8px;
  margin-left: 14px;
  margin-bottom: 14px;
  width: 77px;
  height: 77px;
  border-bottom-width: 2px;
  border-right-width: 2px;
}
.text-cut-notify {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-cut-notify-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-reponsive {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@media (max-width: 350px) {
  .text-reponsive {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@keyframes move {
  from {
    height: 0px;
  }
  to {
    height: 140px;
  }
}
