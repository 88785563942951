.contain {
  min-height: 100vh;
  width: 100%;
  height: 100;
  font-family: "Montsserat";
}
.containerNotify {
  background: url(../fontawesome/image/backgroundNotify.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.containerNotify__background {
  border-radius: 30px 30px 0 0;
  background: #fff;
  overflow: hidden;
}
.containerNotify__background-tc {
  border-radius: 30px 30px 30px 30px;
  background: #fff;
  overflow: hidden;
}
.containerNotify__header-btnBack {
  background: url(../fontawesome/image/backbtnNoti1.svg);
  background-repeat: no-repeat;
}
.containerNotify__header-btnBlue {
  background: url(../fontawesome/image/backbtnNoti-blue.png);
  background-repeat: no-repeat;
  width: 58px;
}
.containerNotify__header-content {
  position: absolute;
  left: 51%;
  transform: translateX(-50%);
  text-align: center;
}
.containerNotify__background-tc .containerNotify__background-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.containerNotify__background .containerNotify__background-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.containerNotify__background .containerNotify__background-listnoti {
  overflow-y: auto;
  max-height: 90%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.containerNotify__background ul::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.containerNotify__background-itemColor {
  background-color: bisque;
}
.border_btn-agree {
  border: 2px solid #09e7f7;
}
@media (max-width: 376px) {
  .containerNotify__background .containerNotify__background-listnoti {
    overflow-y: auto;
    max-height: 90%;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
}
