.annouce-prize {
  background-image: url(../../assets//fontawesome//image/annouce-prize-bg.png);
  background-repeat: no-repeat;
 
}
.bg-wheel-fake{
  background-image: url(../../assets/fontawesome/image/bg-wheel-fake.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
}