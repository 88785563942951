.icon-infor-li {
  padding: 15px 15px 15px 20px;
}
.content-info-li {
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
}

@media (max-height: 730px) {
  .footer-infor {
    margin-top: 100px;
  }
}

@media (min-height: 800px) {
  .footer-infor {
    margin-top: 280px;
  }
}
@media (min-height: 900px) {
  .footer-infor {
    margin-top: 350px;
  }
}
